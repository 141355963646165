/*eslint no-unused-vars: "off"*/

/**
 * @see: https://github.com/jaredpalmer/formik/tree/master/examples
 */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Slider, Radio, RadioGroup, Checkbox, FormGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';

import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';

// s4n 
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Preloader from './../../s4n/Preloader'
import classNames from 'classnames';
import Yup from './../../s4n/Yup';
import FormHelper from './../../s4n/Form'
// import { DisplayFormikState } from "./../formikHelper"



const styles = theme => ({
  // paper: {
  //   marginTop: theme.spacing.unit * 8,
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${theme
  //     .spacing.unit * 5}px`
  // },
  success: {
    backgrounColor: "#4caf50",
    borderColor: "#4caf50",
  }
});


// @see: https://fontawesome.com/icons?d=gallery&q=faces&m=free
const marks = [
    {
        value: 1,
        label: <i className="far fa-sad-cry"></i>,
    },
    {
        value: 2,
        label: <i className="far fa-frown"></i>,
    },
    {
        value: 3,
        label: <i className="far fa-meh"></i>,
    },
    {
        value: 4,
        label: <i className="far fa-smile"></i>,
    },
    {
        value: 5,
        label: <i className="far fa-smile-beam"></i>,
    },
];



const FormStateless = (props) => {
  const { classes, intl } = props;
  const t = intl.formatMessage;

  const [formStatus, setFormStatus] = useState({
    success: null,
    class: null,
    message: null,
  });

  const [state, setState] = useState({
    loading: false,
    error: false,
    formSchema: null,
  });

  const formSchema = {
    sections: [
      {
        title: t({ id: "soft4net_webform_quality_check_form_section_1" }),
        elements: [
          {
            label: 'z_jakich_uslug_firmy_heuresis_mieli_panstwo_okazje_korzystac',
            elements: ['szkolenia', 'doradztwo', 'badania', 'inne']
          },
        ]
      },
      {
        title: t({ id: "soft4net_webform_quality_check_form_section_2" }),
        elements: [
          {
            label: 'jak_czesto_wspolpracuja_panstwo_z_firma_heuresis',
            elements: ['raz_w_miesiacu_lub_czesciej', 'kilka_razy_w_roku', 'raz_w_roku_lub_rzadziej', 'incydentalnie']
          },
        ]
      },
      {
        title: t({ id: "soft4net_webform_quality_check_form_section_3" }),
        elements: [
          'ogolny_poziom_wspolpracy_obslugi_', 
          // 'markup_01', 
          'profesjonalizm_kadr', 
          'zaangazowanie', 
          'kultura_osobista', 
          'pomoc_w_rozwiazywaniu_problemow', 
          'kontakt_z_klientem', 
          'ocena_dostepnosci_informacji_o_firmie',
        ]
      }
    ]
  }

  const INIT_HAPPINESS_LEVEL = 5; // :(, :|, :)

  let initialValues = {
    // section 
    z_jakich_uslug_firmy_heuresis_mieli_panstwo_okazje_korzystac: [],

    // section 
    jak_czesto_wspolpracuja_panstwo_z_firma_heuresis: null,
    
    // section 
    ogolny_poziom_wspolpracy_obslugi_: INIT_HAPPINESS_LEVEL,
    profesjonalizm_kadr: INIT_HAPPINESS_LEVEL,
    zaangazowanie: INIT_HAPPINESS_LEVEL,
    kultura_osobista: INIT_HAPPINESS_LEVEL,
    pomoc_w_rozwiazywaniu_problemow: INIT_HAPPINESS_LEVEL,
    kontakt_z_klientem: INIT_HAPPINESS_LEVEL,
    ocena_dostepnosci_informacji_o_firmie: INIT_HAPPINESS_LEVEL,

    message: ``,
  }

  const validationSchema = Yup.object().shape({
    // name: Yup.string().required(),
    // email: Yup.string().email().required(),
    z_jakich_uslug_firmy_heuresis_mieli_panstwo_okazje_korzystac: Yup.string().required(),
    jak_czesto_wspolpracuja_panstwo_z_firma_heuresis: Yup.string().required(),
    // message: Yup.string().required(),
  })

  // Set data for FormHelper START
  // set form ID!!!
  initialValues.webform_id = 'heuresis_ocena_jakosci_';
  // oFormHelper.formEndpoint = '';
  const oFormHelper = new FormHelper(intl);
  
  oFormHelper.t = t;
  oFormHelper.setFormStatus = setFormStatus;
  oFormHelper.state = state;
  oFormHelper.setState = setState;
  // Set data for FormHelper STOP

  /**
   * @see: https://reactjs.org/docs/hooks-effect.html
   * Same as componentDidMount() hook because we pass an empty array [] as second argument,
   * this argument watches for changes in passed arguments
   * In componentDidMount we have access to window object elsewhere not always!!!
   */
  useEffect(() => {
    // Form schema does not contain markup elements!
    oFormHelper.getFormSchema(initialValues.webform_id);
  }, []);



  const valuetext = value => {
    return `${value}°C`;
  }

  const valueLabelFormat = value => {
    return marks.findIndex(mark => mark.value === value) + 1;
  }

  const handleSliderChange = (event, newValue) => {
    // setValue(newValue);
    // console.log(event)
    // setFieldValue(, newValue);
  };



  return (
    <React.Fragment>
      {oFormHelper.state.loading ? // or state.loading this is a reference
        <Preloader /> : 
        <Formik
          initialValues={initialValues}
          onSubmit={oFormHelper.onSubmit}
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values,
              errors,
              status,
              touched,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,

              setFieldValue

            } = props;

            // console.log(status)

            const formStatusClass = classNames({
              // "mb-0": true,
              "alert": true,
              "d-none": null === formStatus.class,
              [`alert-${formStatus.class}`]: true,
            });

            return (
              <Form 
                onSubmit={handleSubmit} 
                // className={initialValues.webform_id}
                className="quality-check"
              >

                {formSchema.sections.map((section, index) => {
                  return (
                      <div className="row mb-3">
                        <div className="col">
                          <h3>{section.title}</h3>
                          {/* {2 === index ? 
                            <i className="d-block pb-5">{ t({ id: `soft4net_webform_quality_check_form_section_3_opis_skali` }) }</i>
                            : null} */}
                          {section.elements.map((element) => {
                            const elementLabel = typeof element === `object` ? element.label : element;

                            return (
                              <React.Fragment>
                                
                                {0 === index ? 
                                  // @see: https://codesandbox.io/s/o5pw1vx916
                                  <FieldArray
                                    name={elementLabel}
                                    render={arrayHelpers => (

                                      // @see: https://material-ui.com/components/checkboxes/
                                      <FormGroup 
                                        aria-label={oFormHelper.getFieldTitle(elementLabel)} 
                                        name={elementLabel} 
                                        // value={value} 
                                        // value={formStatus.formElements[elementLabel]} 
                                        // value={values.z_jakich_uslug_firmy_heuresis_mieli_panstwo_okazje_korzystac}
                                        // onChange={handleChange} 
                                        row
                                        error={errors[elementLabel]} // implemeneted custom handling
                                      >

                                            {/* @see: https://codesandbox.io/s/o5pw1vx916 */}
                                            {oFormHelper.getFieldItems(elementLabel).map((item, index) => {
                                              const input = item.enum[0];

                                              return <FormControlLabel
                                                value={input}
                                                control={
                                                  <Checkbox 
                                                    // value={values.z_jakich_uslug_firmy_heuresis_mieli_panstwo_okazje_korzystac}
                                                    name={elementLabel}
                                                    color="primary"
                                                    checked={values[elementLabel].includes(input)} // this controls material ui if checked and reset, this reflects current state: if checked, what value has!!!
                                                    onChange={e => {
                                                      if (e.target.checked) {
                                                        arrayHelpers.push(e.target.value);
                                                      } else {
                                                        const idx = values[elementLabel].indexOf(e.target.value);
                                                        arrayHelpers.remove(idx);
                                                      }
                                                    }}
                                                    // onChange={(event, newValue) => {
                                                    //   handleChange(event)
                                                    //   // setFieldValue(elementLabel, values[elementLabel].concat(newValue));
                                                    //   // setFieldValue(elementLabel, values[elementLabel].concat(event.target.value));
                                                    // }}
                                                  />
                                                }
                                                label={ item.title }
                                                // labelPlacement="end"
                                              />
                                            })}

                                        </FormGroup>

                                    )}
                                  /> // FieldArray
                                : null}

                                {1 === index ? 
                                  // @see: https://material-ui.com/components/radio-buttons/
                                  <RadioGroup 
                                    aria-label={oFormHelper.getFieldTitle(elementLabel)} 
                                    name={elementLabel} 
                                    // value={value} 
                                    // value={values[elementLabel]} 
                                    onChange={(event) => {
                                      handleChange(event)
                                    }} 
                                    // onChange={handleChange} 
                                    row
                                    error={errors[elementLabel]} // implemeneted custom handling
                                  >

                                      {oFormHelper.getFieldItems(elementLabel).map((item, index) => {
                                        const radio = item.enum[0];

                                        return <FormControlLabel
                                          value={radio}
                                          control={
                                            <Radio 
                                              color="primary"
                                              checked={radio === values[elementLabel]} // this controls material ui if checked and reset, this reflects current state: if checked, what value has!!!
                                            />
                                          }
                                          label={ item.title }
                                          // label={ t({ id: `soft4net_webform_quality_check_form_section_element_${elementLabel}_${radio}` }) }
                                          // labelPlacement="end"
                                        />
                                      })}

                                  </RadioGroup>
                                  : 
                                  null
                                }

                                {2 === index ? 
                                  <React.Fragment>
                                    {`profesjonalizm_kadr` === elementLabel && 
                                      <strong className="d-block pt-5">{ t({ id: `soft4net_webform_quality_check_form_section_element_markup_01` }) }</strong>}
                                      
                                    <strong className="d-block py-5">{ t({ id: `soft4net_webform_quality_check_form_section_element_${elementLabel}` }) }</strong>
                                    {/* 
                                      @see: https://material-ui.com/components/slider/ 
                                      @see: https://github.com/mui-org/material-ui/issues/13485
                                    */}
                                    <div className="container-fluid">
                                      <Slider
                                        value={values[elementLabel]} // this controls material ui if checked and reset, this reflects current state: if checked, what value has!!!
                                        // onChange={handleSliderChange}
                                        onChange={(event, newValue) => {
                                          // setFieldValue(elementLabel, event.currentTarget.files[0]);
                                          setFieldValue(elementLabel, newValue);
                                        }}
                                        defaultValue={1}
                                        getAriaValueText={valuetext}
                                        // aria-labelledby="discrete-slider"
                                        aria-labelledby="discrete-slider-custom"
                                        // valueLabelDisplay="auto"
                                        valueLabelDisplay="on"
                                        step={1}
                                        marks={marks}
                                        min={1}
                                        max={5}
                                      />
                                    </div>
                                  </React.Fragment>
                                  : 
                                  null
                                }

                              </React.Fragment>
                            )
                          })}                    
                        </div>
                      </div>
                  )
                })}

                <div className="row mt-5 mb-3">
                  <div className="col">
                    <h3>{oFormHelper.getFieldTitle(`message`)}</h3>
                    <TextField
                      id={`${initialValues.webform_id}_message`}
                      // label={ oFormHelper.getFieldTitle(`message`) }
                      label={ t({ id: "soft4net_webform_contact_s4n_form_message" }) }
                      name="message"
                      error={errors.message && touched.message}
                      className={classes.textField}
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.message && touched.message) && errors.message}
                      margin="normal"

                      // display as textarea
                      multiline={true}
                      rows="5"
                      rowsMax="5"

                    />
                  </div>
                </div>

                {/* Form status messages */}
                <div className="row form-status">
                  <div className="col">
                    <div dangerouslySetInnerHTML={{ __html: formStatus.message }} className={formStatusClass} role="alert">{/* {formStatus.message} */}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <DialogActions>
                      <Button
                        type="button"
                        color="secondary"
                        variant="contained"
                        className="outline"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                      >{ t({ id: "soft4net_form_action_reset" }) }</Button>

                      {isSubmitting ? 
                        <CircularProgress /> : 
                        <Button 
                          type="submit" 
                          variant="contained" 
                          color="primary"
                          className={formStatus && formStatus.success ? 'green' : null}
                          disabled={isSubmitting}>{ t({ id: "soft4net_form_action_submit" }) }</Button>
                      }

                    </DialogActions>
                  </div>
                </div>



                {/* <DisplayFormikState {...props} /> */}



              </Form>
            );
          }}
        </Formik>
      }
      </React.Fragment>
    )
}

export default withStyles(styles)(injectIntl(FormStateless))